import React, { useContext, useState } from 'react'
import { InitializeContext } from '../../Layouts/Root';
import "./Header.css";

export default function Header() {
  const { home, services, about, scrollToSection, skills, portfolio, contact } = useContext(InitializeContext);

  /*=============== Change background with scroll ===============*/

  const scrollVisible = () => {
    const header = document.querySelector(".header");
    if (header) {
      if (window.scrollY >= 80) {
        header.classList.add("scroll-header");
      } else {
        header.classList.remove("scroll-header");
      }
    }
  };

  window.addEventListener("scroll", scrollVisible);

  /*=============== Toggle Menu ===============*/
  const [theme, setTheme] = useState<Boolean>(false);
  const [Toggle, showMenu] = useState<Boolean>(false);
  const [activeNav, setActiveNav] = useState<null>(home);

  const toggleClass = () => {
    // set on local storage
    // localStorage.setItem("theme", JSON.stringify(theme));
    setTheme(document.body.classList.toggle("dark"));
  };

  // useEffect(() => {
  //   // get theme from local storage and set it
  //   const theme = JSON.parse(localStorage.getItem("theme") || "false");
  //   setTheme(theme);
  // }, [theme]);

  return (
    <header className='header'>
      <nav className="nav container">
        <a href="/" className="nav__logo">Kazi Mahbubur Rahman</a>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
        
          <ul className="nav__list grid">
            <li className="nav__item" onClick={() => setActiveNav(home)}>
              <span onClick={() => scrollToSection(home)} className={activeNav === home ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-estate nav__icon"></i> Home
              </span>
            </li>
            <li className="nav__item" onClick={() => setActiveNav(about)}>
              <span onClick={() => scrollToSection(about)} className={activeNav === about ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-user nav__icon"></i>About
              </span>
            </li>
            <li className="nav__item" onClick={() => setActiveNav(skills)}>
              <span onClick={() => scrollToSection(skills)} className={activeNav === skills ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-file-alt nav__icon"></i>Skills
              </span>
            </li>
            <li className="nav__item" onClick={() => setActiveNav(services)}>
              <span onClick={() => scrollToSection(services)} className={activeNav === services ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-briefcase-alt nav__icon"></i>Services
              </span>
            </li>
            <li className="nav__item" onClick={() => setActiveNav(portfolio)}>
              <span onClick={() => scrollToSection(portfolio)} className={activeNav === portfolio ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-scenery nav__icon"></i>Portfolio
              </span>
            </li>
            <li className="nav__item" onClick={() => setActiveNav(contact)}>
              <span onClick={() => scrollToSection(contact)} className={activeNav === contact ? "nav__link active-link" : "nav__link"}>
                <i className="uil uil-message nav__icon"></i>Contact
              </span>
            </li>
            <li className="nav__item theme" onClick={toggleClass} title={`${theme ? 'Click to Light theme' : 'Click to Dark theme'}`}>
              {
                theme ? <i className='bx bxs-sun'></i> : <i className='bx bxs-moon'></i>
              }
            </li>
          </ul>

          <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
        </div>

        <ul className="nav__toggle">
          <li className="nav__item theme__phone" onClick={toggleClass}>
            {
              theme ? <i className='bx bxs-sun'></i> : <i className='bx bxs-moon'></i>
            }
          </li>
        </ul>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className='bx bxs-grid-alt'></i>
        </div>
      </nav>
    </header>
  )
}
