import React, { useContext } from 'react'
import { InitializeContext } from '../../Layouts/Root';
import "./Footer.css";


export default function Footer() {
  const { about, portfolio, testimonial, scrollToSection } = useContext(InitializeContext);
  return (
    <footer className="footer">
      <div className="footer__container container">

        <h1 className="footer__title">Kazi Mahbubur Rahman</h1>

        <ul className="footer__list">
          <li onClick={() => scrollToSection(about)}>
            <span className="footer__link">About</span>
          </li>
          <li onClick={() => scrollToSection(portfolio)}>
            <span className="footer__link">Portfolio</span>
          </li>
          <li onClick={() => scrollToSection(testimonial)}>
            <span className="footer__link">Testimonial</span>
          </li>
        </ul>

        <div className="footer__social">
          <a href="https://facebook.com/MahbubDev" className="footer__social-link" target="_blank" rel="noreferrer">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="https://github.com/NoobMahbub" className="footer__social-link" target="_blank" rel="noreferrer">
            <i className="bx bxl-github"></i>
          </a>
          <a href="https://linkedin.com/in/MahbubDev" className="footer__social-link" target="_blank" rel="noreferrer">
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
        <span className="footer__copy">
          &copy; Kazi Mahbubur Rahman {new Date().getFullYear()} || All rights reserved.
        </span>
      </div>
    </footer>
  )
}
